import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { queries, order, fixTVCrew } from '../parts/functions';
import { FigureImage, FigureModal } from '../parts/figure';
import { PersonList, CrumbList } from '../parts/list';
import { OpengraphHelmet, Page, Section, SingleProperty } from '../parts/page';
import { AmazonLink, IMDBLink } from '../parts/links';

export const EpisodePage = () => {
    let { tvParam, seasonParam, episodeParam } = useParams();
    let [series, setSeries] = useState(false);
    let [episode, setEpisode] = useState(false);
    let [modal, setModal] = useState(false);

    useEffect(() => {
        setEpisode(false);
        queries([
            [
                'tv/' + tvParam,
                '&append_to_response=credits,videos,images,external_ids&include_image_language=null,en',
            ],
            [
                `tv/${tvParam}/season/${seasonParam}/episode/${episodeParam}`,
                '&append_to_response=credits,videos,images&include_image_language=null,en',
            ],
        ]).then((d) => {
            // console.log(d,seasonParam)

            d[1].tv_id = tvParam;
            d[1].tv_name = d[0].name;
            d[1].tv_imdb_id = d[0].imdb_id;

            fixTVCrew(d[0], d[1]);

            //console.log(d[1])

            d[0].imdb_id = d[0].imdb_id ?? d[0].external_ids?.imdb_id ?? false;
            setSeries(d[0]);
            setEpisode(d[1]);
            //console.log(episode)
        });
    }, [seasonParam, tvParam, episodeParam]);

    const opengraph = episode
        ? {
              title: `${series.name} - ${episode.name}`,
              description: episode.overview,
              image: episode.still_path,
              url: `/tv/${tvParam}/${seasonParam}/${episodeParam}}`,
          }
        : null;

    return (
        <Page>
            {episode && (
                <>
                    <OpengraphHelmet opengraph={opengraph} />
                    <div className="tv-info container">
                        <div>
                            <h2>{episode.name}</h2>
                        </div>
                        <div className="single-description">
                            <ul className="thumbs">
                                {episode.images.stills.length > 0 &&
                                    episode.images.stills.map((o, i) => (
                                        <li
                                            key={i}
                                            className="thumb"
                                            onClick={() => {
                                                setModal(o.file_path);
                                            }}
                                        >
                                            <FigureImage src={o.file_path} />
                                        </li>
                                    ))}
                            </ul>

                            <CrumbList
                                list={[
                                    [`/tv/${tvParam}`, episode.tv_name],
                                    [`/tv/${tvParam}/${seasonParam}`, 'Season ' + seasonParam],
                                    ['', 'Episode ' + episodeParam],
                                ]}
                            />

                            <div className="single-property">
                                <IMDBLink id={series.imdb_id} label="IMDb" />
                                <AmazonLink search={episode.tv_name} label="Amazon" />
                            </div>

                            <SingleProperty title="Air Date" value={episode.air_date} />
                            <SingleProperty title="Overview" value={episode.overview} />
                        </div>
                    </div>
                    {/* <EpisodeList list={episode.episodes} tv={tvParam} title={`${episode.episodes.length} Episodes`} /> */}
                    {/* <SeasonList list={episode.episodes.reverse()} tv={tvParam} title="episode" /> */}

                    <Section className="section-credits" list={[0]}>
                        <PersonList
                            title="Cast"
                            list={order(episode.credits.cast)}
                            showamount={true}
                        />
                        <PersonList
                            title="Guest Stars"
                            list={order(episode.guest_stars)}
                            showamount={true}
                        />
                        <PersonList
                            title="Crew"
                            list={order(episode.credits.crew)}
                            showamount={true}
                        />
                    </Section>

                    {/* <Section className="section-recommendations" list={[0]}>
         <TVList title="Recommendations" list={episode.recommendations.results} />
         <TVList title="Similar" list={episode.similar.results} />
      </Section> */}
                </>
            )}
            {modal && <FigureModal src={modal} setSrc={setModal} />}
        </Page>
    );
};
