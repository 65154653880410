import { sortByProperty } from './functions';

export const defaultCompareTypes = { person: [], movie: [], tv: [] };

const prepareData = {
    people: (data) => {
        let prepped = data
            .map((o) => {
                o.movie = [...o.movie_credits.cast, ...o.movie_credits.crew];
                o.tv = [...o.tv_credits.cast, ...o.tv_credits.crew];
                return o;
            })
            .sort(sortByProperty('name'));
        return prepped;
    },
    movie: (data) => {
        let prepped = data.map((o) => {
            o.people = [...o.credits.cast, ...o.credits.crew];
            return o;
        });
        return prepped;
    },
    tv: (data) => {
        let prepped = data.map((o) => {
            o.people = [...o.aggregate_credits.cast, ...o.created_by, ...o.aggregate_credits.crew];
            return o;
        });
        return prepped;
    },
};

const homogenizeMediaData = (movies, movie) => {
    let foundmovie = [...movies].find((m) => m.id === movie.id);
    if (foundmovie) {
        foundmovie.characters.push(movie.character);
        foundmovie.credit_ids.push(movie.credit_id);
        foundmovie.episode_counts.push(movie.episode_count);
    } else {
        foundmovie = {
            ...movie,
            characters: [movie.character],
            credit_ids: [movie.credit_id],
            episode_counts: [movie.episode_count],
        };
    }
    return foundmovie;
};

const homogenizePeopleData = (people, person) => {
    let foundperson = [...people].find((p) => p.id === person.id);
    if (foundperson) {
        foundperson.characters.push(person.character);
        foundperson.credit_ids.push(person.credit_id);
        foundperson.cast_ids.push(person.cast_id);
        foundperson.episode_counts.push(person?.episode_count);
    } else {
        foundperson = {
            ...person,
            characters: [person.character],
            credit_ids: [person.credit_id],
            cast_ids: [person.cast_id],
            episode_counts: [person?.episode_count],
        };
    }
    return foundperson;
};

export const compareTwoPeople = (tocompare) => {
    const found = checkTwoPeople(...prepareData.people(tocompare));
    return found;
};

export const compareTwoMovies = (tocompare) => checkTwoMovies(...prepareData.movies(tocompare));

export const compareTwoShows = (tocompare) => checkTwoMovies(...prepareData.shows(tocompare));

const checkTwoPeople = (a, b) => {
    let movie_matches = [],
        tv_matches = [];

    for (let i in a.movie) {
        if (
            b.movie.some((e) => e.id === a.movie[i].id) &&
            !movie_matches.some((e) => e.id === a.movie[i].id)
        ) {
            movie_matches.push(a.movie[i]);
        }
    }
    for (let i in a.tv) {
        if (b.tv.some((e) => e.id === a.tv[i].id) && !tv_matches.some((e) => e.id === a.tv[i].id)) {
            tv_matches.push(a.tv[i]);
        }
    }
    const result = { people: [a, b], movie: movie_matches, tv: tv_matches };
    return result;
};

const findAllPeopleMatchingMedia = (type) => (mediaid, people) => {
    return people.filter((person) => person[type].some((e) => e.id === mediaid));
};
const findAllPeopleMatchingMovie = findAllPeopleMatchingMedia('movie');
const findAllPeopleMatchingShow = findAllPeopleMatchingMedia('tv');

export const findAllMediaMatchingPerson = (personid, media) => {
    return media.filter((item) => item.people.some((e) => e.id === personid));
};

export const checkIfTwoArraysAreTheSame = (a, b) => {
    if (a.length !== b.length) return false;
    return a.every((aval) => b.some((bval) => bval.id === aval.id));
};

const compareIntersectionBetweenPeopleAndMedia = (candidates) => {
    if (candidates.length < 2) return null;

    let candidateamounts = candidates.map((item) => item.movie.length + item.tv.length);
    let candidateindex = candidateamounts.indexOf(Math.min(...candidateamounts));
    let candidate = candidates.splice(candidateindex, 1)[0];

    let people_matches = new Set();
    let movie_matches = new Set();
    let show_matches = new Set();
    for (let movie of candidate.movie) {
        let found_candidates = findAllPeopleMatchingMovie(movie.id, candidates);
        if (found_candidates.length === candidates.length) {
            people_matches = people_matches.union(new Set([candidate, ...found_candidates]));
            movie_matches.add(homogenizeMediaData(movie_matches, movie));
        }
    }
    for (let show of candidate.tv) {
        let found_candidates = findAllPeopleMatchingShow(show.id, candidates);
        if (found_candidates.length === candidates.length) {
            people_matches = people_matches.union(new Set([candidate, ...found_candidates]));
            show_matches.add(homogenizeMediaData(show_matches, show));
        }
    }

    let id = [...people_matches]
        .map((p) => +p.id)
        .sort((a, b) => a - b)
        .join(' ');

    if ([...people_matches].length > 1) {
        return {
            id,
            totalMediaMatches: movie_matches.size + show_matches.size,
            movieMatches: movie_matches.size,
            showMatches: show_matches.size,
            peopleMatches: people_matches.size,
            people: [...people_matches],
            movie: [...movie_matches].sort(sortByProperty('release_date')),
            tv: [...show_matches].sort(sortByProperty('first_air_date')),
        };
    } else {
        return null;
    }
};

/**
 * Finds the intersection between media and people based on a given type.
 * @param {string} type - The type of media ('movie' or 'tv') to compare.
 * @param {Array} candidates - The list of media candidates to compare.
 * @returns {Object|null} - An object containing the intersection between media and people, or null if no intersection is found.
 */
const compareIntersectionBetweenMediaAndPeople = (type) => (candidates) => {
    if (candidates.length < 2) return null;

    // Find the media candidate with the least amount of people
    let candidateamounts = candidates.map((item) => item.people.length);
    let candidateindex = candidateamounts.indexOf(Math.min(...candidateamounts));
    let candidate = candidates.splice(candidateindex, 1)[0];

    // Initialize the sets to store the matches
    let people_matches = new Set();
    let media_matches = new Set();

    // Iterate over each person in the candidate media
    for (let person of candidate.people) {
        let candidates_in_people = findAllMediaMatchingPerson(person.id, candidates);
        if (candidates_in_people.length === candidates.length) {
            media_matches = media_matches.union(new Set([candidate, ...candidates_in_people]));
            people_matches.add(homogenizePeopleData(people_matches, person));
        }
    }

    let id = [...media_matches]
        .map((p) => +p.id)
        .sort((a, b) => a - b)
        .join(' ');

    return [...media_matches].length > 1
        ? {
              id,
              totalMediaMatches: media_matches.size,
              mediaMatches: media_matches.size,
              peopleMatches: people_matches.size,
              people: [...people_matches],
              [type]: [...media_matches].sort(
                  sortByProperty(type === 'movie' ? 'release_date' : 'first_air_date')
              ),
          }
        : null;
};

export const compareIntersectionBetweenMoviesAndPeople =
    compareIntersectionBetweenMediaAndPeople('movie');
export const compareIntersectionBetweenShowsAndPeople =
    compareIntersectionBetweenMediaAndPeople('tv');

const checkTwoMovies = (a, b) => {
    let matches = [];
    for (let person of a.people) {
        if (b.people.some((e) => e.id === person.id) && !matches.some((e) => e.id === person.id)) {
            matches.push(person);
        }
    }
    return { movies: [a, b], people: matches };
};

const isArrayInArray = (a, b) => {
    return a.every((aval) => b.some((bval) => bval.id === aval.id));
};

const sortArrayBySubArrayLength = (reverse) => (a, b) => {
    if (a.length < b.length) return reverse ? 1 : -1;
    if (a.length > b.length) return reverse ? -1 : 1;
    return 0;
};

export const doCompare = (arr = [], type = 'people') => {
    const tocompare = prepareData[type](arr);
    const comparator =
        type === 'people'
            ? compareIntersectionBetweenPeopleAndMedia
            : compareIntersectionBetweenMediaAndPeople(type);

    const matches = [];

    /**
     * Recursively generates all possible combinations of elements from a given list.
     *
     * @param {Array} prefix - The prefix array representing the current combination.
     * @param {Array} list - The list of elements to generate combinations from.
     */
    const compareSet = (prefix, list) => {
        for (let i = 0; i < list.length; i++) {
            let newprefix = [...prefix, list[i]];
            // console.log(newprefix.length, ...newprefix);
            if (newprefix.length > 1) matches.push([...newprefix]);
            // console.log(matches.at(-1));
            compareSet(newprefix, list.slice(i + 1));
        }
    };

    compareSet([], tocompare);
    matches.sort(sortArrayBySubArrayLength(true));

    const finalmatches = [];

    for (let i in matches) {
        let comparison = comparator([...matches[i]]);
        if (comparison) {
            if (
                finalmatches.some((e) => {
                    const isPeople = isArrayInArray(comparison.people, e.people);
                    const isMovie =
                        comparison.movie !== undefined &&
                        isArrayInArray(comparison.movie || [], e.movie || []);
                    const isTV =
                        comparison.tv !== undefined &&
                        isArrayInArray(comparison.tv || [], e.tv || []);
                    return type === 'people'
                        ? isPeople && isMovie && isTV
                        : type === 'movie'
                        ? isMovie && isPeople
                        : isTV && isPeople;
                })
            )
                continue;
            finalmatches.push(comparison);
        }
    }

    if (type === 'people') {
        finalmatches
            .sort(sortByProperty('totalMediaMatches', true))
            .sort(sortByProperty('peopleMatches', true));
    } else {
        finalmatches
            .sort(sortByProperty('peopleMatches', true))
            .sort(sortByProperty('mediaMatches', true));
    }

    return [...finalmatches];
};
