import React from 'react';
import { useStoreContext } from '../store/storecontext';

export const Badge = ({value}) => {
   return <>
      { value>0 && <div className="badge">{value}</div> }
   </>;
}

export const CompareBadge = () => {
   const [{compare}] = useStoreContext();

   return <Badge value={compare.length} />;
}