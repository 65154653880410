import React, { useEffect, useRef, useState } from 'react';
import * as r from 'react-router-dom';
import { FigureImage } from './figure';

import { Form,Input } from './forms';
import { slowQueries } from './functions';

// const debounce = (func, timeout = 3000) => {
//    let timer;
//    return (...args) => {
//      clearTimeout(timer);
//      timer = setTimeout(() => { func(...args); }, timeout);
//    };
//  }

const SearchForm = () => {
   const { searchParam } = r.useParams();

   const inputref = useRef();

   const [search,setSearch] = useState("");
   const [searchTo,setSearchto] = useState(false);
//    const [linkTo,setLinkto] = useState(false);
   const [quickresults,setQuickresults] = useState([]);
   const [quickopen,setQuickopen] = useState(false);
   const [quickchoice] = useState(false);

   useEffect(()=>{
      if(searchParam!==undefined) {
         setSearchto(false);
         setSearch(searchParam);
      }
   },[searchParam]);

   

   const searchHandler = (e) => { setSearch(e.target.value); }
   const blurHandler = (e) => { setTimeout(()=>{setQuickopen(false)},200); }
   const focusHandler = (e) => { setTimeout(()=>{setQuickopen(true)},100); }
   const submitHandler = (e) => {
      if(search!=="") {
         setSearchto(true);
         setQuickopen(false);
         setTimeout(()=>{setSearchto(false);},1);
      }
   }

   const quickQuery = (search) => {
      if (search !== '') {
         slowQueries([
            ["search/person", "&page=1&region=US&query="+search],
            ["search/movie", "&page=1&region=US&query="+search],
            ["search/tv", "&page=1&region=US&query="+search]
         ]).then((d)=>{
            const person = d[0].results.map(d=>({...d,type:'person'}));
            const movie = d[1].results.map(d=>({...d,type:'movie'}));
            const tv = d[2].results.map(d=>({...d,type:'tv'}));

            const results = [[],[],[]];
            
            do {
               const p = person.shift();
               const m = movie.shift();
               const t = tv.shift();
               if (p) results[0].push(p);
               if (m) results[1].push(m);
               if (t) results[2].push(t);
            } while ((person.length || movie.length || tv.length) && results.flat().length < 10);

            // console.log(results.flat())
            setQuickresults(results.flat())
         });
      }
   }

   useEffect(()=>{
      const timer = setTimeout(()=>{
         quickQuery(search)
      }, 500);
      return () => clearTimeout(timer);
   },[search])

   // useEffect(()=>{
   //    const getQuickId = (num) => quickresults.length ? wrapNumber(quickresults.length)(quickchoice+num) : 0;
   //    const keyHandler = (e) => {
   //       switch(e.code) {
   //          case "ArrowDown":
   //             setQuickchoice(getQuickId(1));
   //             break;
   //          case "ArrowUp":
   //             setQuickchoice(getQuickId(-1));
   //             break;
   //          case "Enter": 
   //       }
   //    }
   //    inputref.current.addEventListener("keydown", keyHandler);
   //    return () => inputref.current.removeEventListener("keydown", keyHandler);
   // }, [quickresults,quickchoice,inputref])

   return <>
      { searchTo && <r.Navigate replace to={`/search/${search}`} /> }
      {/* { linkTo && <r.Navigate replace to={linkTo} /> } */}
      <Form id="search-main-form" className="hotdog" onSubmit={submitHandler}>
         <Input type="search"
            id="search-main-input"
            value={search}
            onChange={searchHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            placeholder="Search for something"
            ref={inputref} />
      </Form>
      {quickopen && quickresults.length > 0 && <div className={`droplist`}>
         {quickresults.map((o,i)=>{
            return <div key={i} className={`droplist-item ${quickchoice === i ? 'active' : ''}`}>
               {o.type === 'person' ? <>
                  <a href={`/person/${o.id}`}>
                     <FigureImage src={o.profile_path} /> 
                     {o.name}
                  </a>
               </> : o.type === 'movie' ? <>
                  <a href={`/movie/${o.id}`}>
                     <FigureImage src={o.poster_path} /> 
                     {o.title}
                  </a>
               </> : <>
                  <a href={`/tv/${o.id}`}>
                     <FigureImage src={o.poster_path} /> 
                     {o.name}
                  </a>
               </>}
            </div>
         })}
      </div>}
   </>;
}

export const Search = () => {
   return <r.Routes>
      <r.Route path="/search/:searchParam" element={<SearchForm />} />
      <r.Route path="/*" element={<SearchForm />} />
   </r.Routes>;
}