import React from 'react';

import { TVList } from '../parts/list';
import { Page } from '../parts/page';
import { query } from '../parts/functions';
import { useInfiniteLoading } from '../parts/infiniteLoading';
import { Route, Routes, useParams } from 'react-router-dom';

const filterTypes = {
    genre: 'with_genres',
    year: 'primary_release_year',
    keyword: 'with_keywords',
    company: 'with_companies',
};

const FilterType = () => {
    let { filterType, filterValue, filterName } = useParams();

    const getItems = ({ page }) => {
        // console.log(page)
        return query(`discover/tv`, `&${filterTypes[filterType]}=${filterValue}&page=${page}`).then(
            (d) => {
                // console.log(d)
                return { items: d.results, totalPages: d.total_pages };
            }
        );
    };

    const { items, hasNext, hasPrevious, loadNext, loadPrevious, loadMoreRef } = useInfiniteLoading(
        {
            getItems,
            options: {
                loadingType: 'partial',
                preload: 'off',
                partialInfiniteLimit: 6,
            },
        }
    );

    return (
        <Page>
            {hasPrevious && (
                <button className="btn--load-more" type="button" onClick={() => loadPrevious()}>
                    Load Previous
                </button>
            )}
            {items.length > 0 ? (
                <>
                    <div className="container" style={{ textTransform: 'capitalize' }}>
                        <h2>
                            {filterType} : {filterName ? filterName : filterValue}
                        </h2>
                    </div>
                    <TVList className="fullsize" title="" list={items} />
                </>
            ) : (
                <div>Loading...</div>
            )}
            {hasNext && (
                <div style={{ textAlign: 'center' }}>
                    <button
                        ref={loadMoreRef}
                        className="btn btn--load-more"
                        type="button"
                        onClick={() => loadNext()}
                    >
                        Load Next
                    </button>
                </div>
            )}
        </Page>
    );
};

export const ShowsPage = () => {
    return (
        <Routes>
            <Route path=":filterType" element={<FilterType />}>
                <Route path=":filterValue" element={<FilterType />}>
                    <Route path=":filterName" element={<FilterType />} />
                </Route>
            </Route>
        </Routes>
    );
};
