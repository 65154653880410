import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { query, siftCrew, getYear, uniques, timeBreakdown } from '../parts/functions';
import { FigureGallery, FigureImage, FigureModal } from '../parts/figure';
import { MovieList, PersonList } from '../parts/list';
import {
    Page,
    Section,
    SingleProperty,
    ChildProperty,
    Container,
    OpengraphHelmet,
} from '../parts/page';
import { MovieOverlay } from '../parts/overlay';
import { AmazonLink, IMDBLink } from '../parts/links';
import { useStoreContext } from '../store/storecontext';
import { VideoThumbs } from '../parts/video';
import { Modal } from '../parts/modal';

const Companies = ({ companies }) => {
    return (
        <>
            {companies.map((o, i) => (
                <div className="company" key={i}>
                    <a href={`/movies/company/${o.id}/${o.name}`}>
                        <FigureImage src={o.logo_path} />
                    </a>
                </div>
            ))}
        </>
    );
};

const Genres = ({ genres }) => {
    return (
        <div className="comma-list genres">
            {genres.map((o, i) => (
                <Link key={i} to={`/movies/genre/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </div>
    );
};

const Keywords = ({ keywords }) => {
    return (
        <div className="comma-list keywords">
            {keywords.map((o, i) => (
                <Link key={i} to={`/movies/keyword/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </div>
    );
};

const getReleases = (releases) => {
    const ratings = (Array.isArray(releases) ? releases : []).reduce((r, o) => {
        if (o.certification !== '') r.push(o.certification);
        return r;
    }, []);
    return ratings;
};
const getRating = (movie) => {
    const releases = movie?.release_dates?.results ?? {};

    const ratings = [
        ...new Set(
            releases
                .map((d) => {
                    const r = getReleases(d.release_dates);
                    return r;
                })
                .flat()
        ),
    ];

    const us_release = releases.find((o) => o.iso_3166_1 === 'US') ?? {};
    const other_productions = movie.production_countries.filter((o) => o.iso_3166_1 !== 'US');
    const production_releases = other_productions
        .map((p) => {
            const r = releases.find((o) => o.iso_3166_1 === p.iso_3166_1);
            const ratings = getReleases(r?.release_dates);
            return ratings;
        })
        .flat();

    const rating = getReleases(us_release.release_dates)[0] ?? production_releases[0] ?? null;

    return { rating, ratings };
};

export const MovieProfile = ({ movie, setModal }) => {
    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();
    const ratings = getRating(movie);
    const release_year = getYear(movie.release_date, movie.status);
    return movie ? (
        <div className="movie-info container">
            <div className="single-image canclick">
                <FigureImage
                    src={movie.poster_path}
                    onClick={() => {
                        setModal(movie.poster_path);
                    }}
                    adult={!adult && movie.adult}
                />
                <MovieOverlay data={movie} />
            </div>
            <div className="single-description">
                <div className="single-title">{movie.title}</div>

                <div>
                    <div className="comma-list">
                        <a href={`/movies/year/${release_year}`} title={movie.release_date}>
                            {release_year}
                        </a>
                        {movie.runtime ? (
                            <span title={movie.runtime}>{timeBreakdown(movie.runtime)}</span>
                        ) : (
                            <></>
                        )}
                        {ratings.rating ? (
                            <span title={ratings.ratings.join(', ')}>{ratings.rating}</span>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="single-property">
                        <IMDBLink id={movie.imdb_id} label="IMDb" />
                        <AmazonLink
                            search={`${movie.title} ${getYear(movie.release_date)}`}
                            label="Amazon"
                        />
                    </div>
                </div>
                <ChildProperty title="Genres" length={movie.genres.length}>
                    <Genres genres={movie.genres} />
                </ChildProperty>
                <ChildProperty title="Keywords" length={movie.keywords.keywords.length}>
                    <Keywords keywords={movie.keywords.keywords} />
                </ChildProperty>
                <SingleProperty title="Overview" value={movie.overview} more paragraph />
                <SingleProperty
                    title="Production Companies"
                    value={
                        <div className="companies">
                            <Companies companies={movie.production_companies || []} />
                        </div>
                    }
                />
            </div>
        </div>
    ) : (
        <></>
    );
};

export const MoviePage = () => {
    let { movieParam } = useParams();
    let [movie, setMovie] = useState(false);
    let [collection, setCollection] = useState(false);
    let [modal, setModal] = useState(false);
    let [videoModal, setVideoModal] = useState(false);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setMovie(false);
        setCollection(false);
        query(
            `movie/${movieParam}`,
            `&append_to_response=credits,videos,images,recommendations,similar,keywords,release_dates&include_image_language=null,en`
        ).then((d) => {
            setMovie(d);
            // console.log(d);
        });
    }, [movieParam]);

    useEffect(() => {
        if (movie.belongs_to_collection) {
            query(`collection/${movie.belongs_to_collection.id}`).then((c) => {
                setCollection(c);
            });
        }
    }, [movie]);

    const opengraph = movie
        ? {
              title: `${movie.title} (${getYear(movie.release_date, movie.status)})`,
              description: movie.overview,
              image: movie.poster_path,
              url: `/movie/${movie.id}/${movie.title.toLowerCase().replace(/[^a-z0-9]/gi, '-')}`,
          }
        : null;

    return (
        <Page backdrop={movie?.images?.backdrops[0]}>
            {movie && (
                <>
                    <OpengraphHelmet opengraph={opengraph} />
                    <MovieProfile movie={movie} setModal={setModal} />
                    <div className="galleries container">
                        {movie.videos.results.length > 0 && (
                            <SingleProperty
                                className="figure-gallery-section"
                                title="Videos"
                                collapse
                                collapseAtStart
                                value={
                                    <VideoThumbs
                                        movies={movie.videos.results}
                                        showModal={setVideoModal}
                                    />
                                }
                            />
                        )}
                        {movie.images.posters.length > 0 && (
                            <SingleProperty
                                className="figure-gallery-section"
                                title="Posters"
                                collapse
                                collapseAtStart
                                value={
                                    <FigureGallery
                                        className="posters"
                                        images={movie.images.posters}
                                        setModal={setModal}
                                        adultHandler={(o) => !adult}
                                    />
                                }
                            />
                        )}
                        {movie.images.backdrops.length > 0 && (
                            <SingleProperty
                                className="figure-gallery-section"
                                title="Backdrops"
                                collapse
                                collapseAtStart
                                value={
                                    <FigureGallery
                                        className="backdrops"
                                        images={movie.images.backdrops}
                                        setModal={setModal}
                                        adultHandler={(o) => !adult}
                                    />
                                }
                            />
                        )}
                        {movie.images.logos.length > 0 && (
                            <SingleProperty
                                className="figure-gallery-section"
                                title="Logos"
                                collapse
                                collapseAtStart
                                value={
                                    <FigureGallery
                                        className="logos"
                                        images={movie.images.logos}
                                        setModal={setModal}
                                        adultHandler={(o) => !adult}
                                    />
                                }
                            />
                        )}
                    </div>

                    {collection && (
                        <Container>
                            <MovieList
                                className="movie-collection"
                                sortorder="releasedateASC"
                                title={collection.name}
                                list={collection.parts}
                                selectId={movie.id}
                            />
                        </Container>
                    )}

                    <Section className="section-credits" list={[0]}>
                        <PersonList
                            title="Cast"
                            list={movie.credits.cast}
                            showamount
                            sortorder="moviecast"
                        />
                        <PersonList
                            title="Crew"
                            list={siftCrew(movie.credits.crew)}
                            showamount
                            sortorder="moviecrew"
                            defaultSort="jobASC"
                            jobs={(() => {
                                return uniques(
                                    movie.credits.crew.reduce((r, o) => [...r, o.job], [])
                                );
                            })()}
                        />
                    </Section>

                    <Section className="section-recommendations" list={[0]}>
                        <MovieList title="Similar" list={movie.similar.results} />
                        <MovieList title="Recommendations" list={movie.recommendations.results} />
                    </Section>
                    {modal && <FigureModal src={modal} setSrc={setModal} />}
                    <Modal
                        show={videoModal !== false}
                        close={() => setVideoModal(false)}
                        content={
                            videoModal ? (
                                <iframe
                                    src={`https://www.youtube.com/embed/${videoModal.key}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                </>
            )}
        </Page>
    );
};
