import React from 'react';
import imdb from '../img/imdb.svg';
import amazon from '../img/amazon.svg';



export const Icon = ({src,alt="icon"}) => {
    return <img src={src} alt={alt} className="svg-icon" />;
}


export const AmazonLink = ({search,label}) => {
    const urisearch = encodeURIComponent(search);
    return search && search !== '' ? <div className="single-amazon"><a href={`https://www.amazon.com/s/?url=search-alias%3Dmovies-tv&field-keywords=${urisearch}&tag=hamiltondraws-20`} target="_blank" rel="noopener noreferrer">{label??<Icon src={amazon} />}</a></div> : <></>;
}
export const IMDBLink = ({id,label}) => {
    return id && id !== '' ? <div className="single-imdb"><a href={`https://imdb.com/title/${id}`} target="_blank" rel="noopener noreferrer">{label??<Icon src={imdb} />}</a></div> : <></>;
}

