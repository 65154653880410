import React from 'react';
import styles from './modal.module.css';

export const Modal = ({show=false, close=()=>{}, type=styles.modalPopup, content}) => {
    return show ? (
        <div className={styles.modal}>
            <div className={styles.modalBack} onClick={close}></div>
            <div className={type}>
                {content}
            </div>
        </div>
    ) : <></>;
}

export const ModalHeader = ({title=''}) => {
    return <div className={styles.header}>{title}</div>;
}
export const ModalBody = ({children}) => {
    return <div className={styles.body}>{children}</div>;
}
export const ModalFooter = ({cta}) => {
    return <div className={styles.footer}>{cta}</div>;
}