import { majorCrewOrder } from './list';

// QUERIES
export const query = (type, query, adult = 'false') => {
    let url =
        `https://api.themoviedb.org/3/${type}` +
        `?api_key=ef5a03167f20245012f066e49b5453a9&language=en-US` +
        `&include_adult=${adult}` +
        (query || '');
    return fetch(url).then((r) => r.json());
};

export const queries = (qs) => Promise.all(qs.map((o) => query(...o)));

export const searchParams = (obj) => '&' + new URLSearchParams(obj).toString();

// Type, Query, Callback --- Pipedata
export const pipeQuery = (t, q, a) => (pd) => query(t, q, a).then((d) => pd.concat([d]));

export const pipeback =
    (fn = () => {}) =>
    (pd) =>
        new Promise((r) => {
            fn(pd);
            r(pd);
        });

export const trace = pipeback(console.log);

// Timing --- Pipedata
export const delay = (t) => (pd) => new Promise((r) => setTimeout(() => r(pd), t));

export const traceDelay = (t) => (pd) =>
    new Promise((r) =>
        setTimeout(() => {
            r(pd);
        }, t)
    );

// Functions --- Initialdata
export const pipe =
    (...fns) =>
    (x) => {
        return fns.reduce((r, f) => r.then(f), Promise.resolve(x));
    };

// Queries, Timing
export const slowQueries = async (qs, fn, t) => {
    // let s = new Date();
    let r = await qs
        .flatMap((q) => [
            pipeQuery(...q),
            pipeback(fn),
            // delay(t)
        ])
        .reduce((r, f) => r.then(f), Promise.resolve([]));
    // console.log((new Date())-s)
    return r;
};

/* Given a curried max value, attempts to bring negative numbers to positive range of loop */
export const unsignNumber = (max) => (n) => n < 0 ? n + max : n;
/* Given a curried max value, attempts to wrap a number over half into a negative number of loop */
export const signNumber = (max) => (n) => n > max * 0.5 ? n - max : n < max * -0.5 ? n + max : n;
/* Given a curried max value, attempts to wrap a number within that max */
export const wrapNumber = (max) => {
    const t = unsignNumber(max);
    const s = signNumber(max);
    return (n) => t(s(n));
};
export const clamp = (min, max) => (n) => n > max ? max : n < min ? min : n;

export const siftCrew = (crew) => {
    return crew.reduce((r, o) => {
        let found = r.find((a) => a.id === o.id);
        if (!found) {
            found = { ...o, jobs: [] };
            r.push(found);
        }
        let foundjobs = o.jobs ? o.jobs.map((j) => j.job) : [o.job];
        found.jobs.push(...foundjobs);

        // this will ensure that jobs are sorted and that the main jobs is set as the job property
        found.jobs = sortArrayByOtherArray(majorCrewOrder)(found.jobs);
        found.job = found.jobs[0];
        return r;
    }, []);
};

export const getAge = (person) => {
    if (!person.birthday) return null;
    let b = new Date(person.birthday);
    let d = person.deathday ? new Date(person.deathday) : new Date();
    return Math.floor((d - b) / 1000 / 60 / 60 / 24 / 365);
};

export const getYear = (d, status = 'Unreleased') => (d ? d.substr(0, 4) : status);

export const showGenres = (d) =>
    (d || []).reduce((r, o) => r + (r === '' ? o.name : ', ' + o.name), '');

export const order = (l, p = 'order', r = !1) =>
    [...l].sort((a, b) => (a[p] > b[p] ? (r ? -1 : 1) : a[p] < b[p] ? (r ? 1 : -1) : 0));
export const reorder = (
    l,
    p1 = (a, b) => a.order > b.order,
    p2 = (a, b) => a.order < b.order,
    r = !1
) => [...l].sort((a, b) => (p1(a, b) ? (r ? -1 : 1) : p2(a, b) ? (r ? 1 : -1) : 0));

export const fixCreators = (a) =>
    a.map((o) => {
        o.job = 'Created By';
        return o;
    });

export const fixTVCrew = (a, b) => {
    // console.log(a,b)
    b.credits.crew = siftCrew(fixCreators(a.created_by).concat(b.credits.crew));
};

export const toCapitalize = (str) => {
    return str === '' ? '' : String(str).substr(0, 1).toUpperCase() + String(str).substr(1);
};

export const uniques = (arr) => [...new Set(arr)];

export const timeBreakdown = (t) => {
    const days = Math.floor(t / 1440);
    const remainder = t % 1440;
    const hours = Math.floor(remainder / 60);
    const minutes = remainder % 60;
    return [days ? [days + 'd'] : [], hours ? [hours + 'h'] : [], minutes ? [minutes + 'm'] : []]
        .flat()
        .join(' ');
};

export const sortByProperty =
    (property, reverse = false) =>
    (a, b) => {
        a = propertyStringToObject(property, a);
        b = propertyStringToObject(property, b);
        if (a < b) return reverse ? 1 : -1;
        if (a > b) return reverse ? -1 : 1;
        return 0;
    };

export const findByProperty = (property, value) => (list) => {
    return list.find((item) => propertyStringToObject(property, item) === value);
};

export const findIndexByProperty = (property, value) => (list) => {
    return list.findIndex((item) => propertyStringToObject(property, item) === value);
};

export const spliceByProperty = (property, value) => (list) => {
    return list.splice(
        list.findIndex((item) => propertyStringToObject(property, item) === value),
        1
    );
};

export const findIndexOfMaxValue = (property) => (list) => {
    return list.reduce((iMax, x, i, arr) => (x[property] > arr[iMax][property] ? i : iMax), 0);
};

export const findIndexOfMinValue = (property) => (list) => {
    return list.reduce((iMin, x, i, arr) => (x[property] < arr[iMin][property] ? i : iMin), 0);
};

export const indexOfMax = (arr) => arr.indexOf(Math.max(...arr));

// if given a string like "property.property.property" and an object, it will return the obj.property.property.property value
export const propertyStringToObject = (str, obj) => {
    return str.split('.').reduce((o, i) => o?.[i], obj);
};

// this function should take an array of strings that are in a particular order
// it will also take an array of objects that should be sorted by a property that might match one of the strings, and so the objects should be in the same order as the strings
// for example, an array of strings ["a", "b", "c", "d"] and an array of objects [{name: "b"}, {name: "a"}, {name: "e"}, {name: "c"}]
// should return [{name: "a"}, {name: "b"}, {name: "c"}, {name: "e"}]
export const sortArrayOfObjects = (sortByArray) => (arrOfObjects, property) => {
    return arrOfObjects.toSorted((a, b) => {
        let da = sortByArray.indexOf(a[property]);
        let db = sortByArray.indexOf(b[property]);
        if (da === -1) da = sortByArray.length;
        if (db === -1) db = sortByArray.length;
        return da - db;
    });
};

export const sortArrayByOtherArray = (sortByArray) => (arr) => {
    return arr.toSorted((a, b) => {
        let da = sortByArray.indexOf(a);
        let db = sortByArray.indexOf(b);
        if (da === -1) da = sortByArray.length;
        if (db === -1) db = sortByArray.length;
        return da - db;
    });
};

export const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1)
        .fill(null)
        .map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};
