import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { imageurl } from './figure';

export const Header = ({title="",children}) => {
   return <header className="navbar"><div className="container">
      {title===""||<h1>{title}</h1>}
      <ul>{children}</ul>
   </div></header>;
}

export const Page = ({
   children,
   className="",
   style={},
   backdrop=false,
}) => {
   if (backdrop) {
      style["--backdropUrl"] = `url(${imageurl(backdrop.file_path,"original")})`;
      className += " has-backdrop";
   }
   return <div className={`page ${className}`} style={style}>
      <OpengraphHelmet />
      {children}
   </div>;
}

export const Container = ({children,className="",style={}}) => {
   return <div className={`container ${className}`} style={style}>{children}</div>;
}

export const Stretch = ({children,className="",style={}}) => {
   return <li className={`flex-stretch ${className}`} style={style}>{children}</li>;
}
export const NoStretch = ({children,className="",style={}}) => {
   return <li className={`flex-none ${className}`} style={style}>{children}</li>;
}


export const HeaderList = ({children,className="",style={}}) => {
   return <div className={`header-list ${className}`} style={style}>{children}</div>;
}


export const Section = ({list=[],className="",children}) => {
   return <>
      { list.length>0 && <div className={`section ${className}`}>{children}</div> }
   </>;
}
export const SectionHeader = ({list=[],className="",title="",shown=false}) => {
   return <>
      {list.length>0 && <h2 className={`container ${className}`}>{title} {shown&&`(${list.length})`}</h2>}
   </>;
}

const getLess = (str,length=50) => {
   if (!str) return;
   const arr = `${str}`.split(' ');
   if (arr.length > length * 1.25) {
      return arr.slice(0,length).join(' ');
   } else return str;
}

const makeParagraphed = (str, paragraph) => {
   if (!str || !paragraph) return str;
   const arr = `${str}`.split(/\n+/);
   return arr.map((o,i)=><p key={i}>{o}</p>);
}

export const SingleProperty = ({className="", title="", value, more=false, paragraph=false, collapse=false, collapseAtStart=false}) => {
   const [showMore,setShowMore] = useState(false);
   const [collapsed,setCollapsed] = useState(collapseAtStart);
   const less = getLess(value,100);
   return value && value!=="" ?
   <div className={`${className} single-property`}>
      {collapse ? 
         <button className="title" onClick={()=>setCollapsed(!collapsed)}>
            <strong>{title}</strong>
            <span>{collapsed ? "▼" : "▲"}</span>
         </button> : 
         <div className="title">
            <strong>{title}</strong>
         </div>
      }
      {!collapsed && 
         <div className="content">{!more || `${less}`.length === `${value}`.length ? makeParagraphed(value, paragraph) :
            !showMore ? <>
               {makeParagraphed(less, paragraph)} <span className="comma-item" onClick={()=>setShowMore(true)}>Show More</span>
            </> : <>
               {makeParagraphed(value, paragraph)} <span className="comma-item" onClick={()=>setShowMore(false)}>Show Less</span>
            </>
         }</div>
      }
   </div> : <></>;
}

export const ChildProperty = ({title="",length=0,children}) => {
   return length > 0 ? <div className="single-property">
      <strong>{title}</strong>
      <div>{children}</div>
   </div> : <></>;
}


export const OpengraphHelmet = ({
   opengraph={
      title: "Filmswith",
      description: "What was the film with that guy in it?",
      image: "/logo_icon@2x.png",
      url: "/",
   },
}) => {
   return <Helmet>
      {opengraph.title && <title>{opengraph.title} - FilmsWith</title>}
      {opengraph.description && <meta name="description" content={opengraph.description} />}
      {Object.entries(opengraph).map(([key,value])=>{
         const ogkey = `og:${key}`;

         if (value?.length > 200) {
            value = value.slice(0,256) + '...';
         }
         if (key === 'image') {
            value = imageurl(value);
         }
         return <meta key={key} property={ogkey} content={value} />
      })}
   </Helmet>
}
