import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import * as f from '../parts/figure';
import { SelectControl } from '../parts/forms';
import { Section, SectionHeader } from '../parts/page';
import { sortArrayOfObjects } from './functions';
//import { order,reorder } from '../parts/functions';

const sortings = {
    recentmovies: [
        ['', 'Most Known For'],
        ['ratingDESC', 'Highest Rating'],
        ['ratingASC', 'Lowest Rating'],
        ['releasedateDESC', 'Latest Release Date'],
        ['releasedateASC', 'Oldest Release Date'],
    ],
    moviecast: [
        ['', 'Relevance'],
        ['personnameASC', 'Actor Alphabetical'],
        ['personcharacterASC', 'Character Alphabetical'],
    ],
    moviecrew: [
        ['jobASC', 'Job'],
        ['', 'Relevance'],
        ['personnameASC', 'Name Alphabetical'],
    ],
    personcast: [
        ['', 'Relevance'],
        ['movietitleASC', 'Title'],
        ['personcharacterASC', 'Character Alphabetical'],
        ['releasedateDESC', 'Most Recent First'],
        ['releasedateASC', 'Oldest First'],
    ],
    personcrew: [
        ['', 'Relevance'],
        ['movietitleASC', 'Title'],
        ['releasedateDESC', 'Most Recent First'],
        ['releasedateASC', 'Oldest First'],
    ],
    tvcast: [
        ['', 'Relevance'],
        ['personnameASC', 'Actor Alphabetical'],
        ['personcharacterASC', 'Character Alphabetical'],
        ['firstairdateDESC', 'Latest First Air Date'],
        ['firstairdateASC', 'Oldest First Air Date'],
        ['episodesDESC', 'Most Episodes'],
    ],
    tvcrew: [
        ['', 'Relevance'],
        ['personnameASC', 'Name Alphabetical'],
    ],
};

export const majorCrewOrder = [
    'Director',
    'Writer',
    'Screenplay',
    'Story',
    'Novel',
    'Created By',
    'Characters',
    'Producer',
    'Co-Producer',
    'Executive Producer',
    'Original Music Composer',
    'Director of Photography',
    'Cinematography',
    'Editor',
    'Casting',
    'Production Design',
    'Art Direction',
];

const sortrating = (dir) => (a, b) => {
    const da = a.vote_count < 10 ? a.vote_average / 10 : a.vote_average;
    const db = b.vote_count < 10 ? b.vote_average / 10 : b.vote_average;
    return dir === 'ASC' ? da - db : db - da;
};
const sortdate = (dir, prop) => (a, b) => {
    const da = new Date(a[prop] || '1800-01-01');
    const db = new Date(b[prop] || '1800-01-01');
    return dir === 'ASC' ? da - db : db - da;
};
const sortprop = (prop, dir) => (a, b) => {
    const da = a[prop];
    const db = b[prop];
    if (da < db) {
        return dir === 'ASC' ? -1 : 1;
    }
    if (da > db) {
        return dir === 'ASC' ? 1 : -1;
    }
    return 0;
};

const sortReducer = (setList, action) => {
    switch (action) {
        case 'ratingASC':
            return [...setList].sort(sortrating('ASC'));
        case 'ratingDESC':
            return [...setList].sort(sortrating('DESC'));
        case 'releasedateASC':
            return [...setList].sort(sortdate('ASC', 'release_date'));
        case 'releasedateDESC':
            return [...setList].sort(sortdate('DESC', 'release_date'));

        case 'firstairdateASC':
            return [...setList].sort(sortdate('ASC', 'first_air_date'));
        case 'firstairdateDESC':
            return [...setList].sort(sortdate('DESC', 'first_air_date'));
        case 'episodesDESC':
            return [...setList].sort(sortdate('DESC', 'episode_count'));

        case 'personnameASC':
            return [...setList].sort(sortprop('name', 'ASC'));
        case 'personnameDESC':
            return [...setList].sort(sortprop('name', 'DESC'));
        case 'personcharacterASC':
            return [...setList].sort(sortprop('character', 'ASC'));
        case 'personcharacterDESC':
            return [...setList].sort(sortprop('character', 'DESC'));
        case 'movietitleASC':
            return [...setList].sort(sortprop('title', 'ASC'));
        case 'movietitleDESC':
            return [...setList].sort(sortprop('title', 'DESC'));

        case 'jobASC':
            return sortArrayOfObjects(majorCrewOrder)([...setList], 'job');

        default:
            return [...setList];
    }
};
const filterReducer = (setList, action, value) => {
    switch (action) {
        case 'job':
            return [...setList].filter((o) => o.jobs.some((o) => o === value));
        case 'character':
            return [...setList].filter((o) => o.character === value);

        default:
            return [...setList];
    }
};

export const FigureList = ({
    type,
    Figure,
    to = (o) => `/${type}/${o.id}`,
    title = '',
    list = [],
    options = [],
    filters = [],
    showamount = false,
    className = '',
    defaultSort = '',
    selectId,
}) => {
    let listref = React.useRef();

    let [thelist, setThelist] = useState(list);

    let [sort, setSort] = useState(defaultSort);
    let [filter, setFilter] = useState('');

    useEffect(() => {
        setThelist(sortReducer([...list], defaultSort));
    }, [list, defaultSort]);

    return (
        <Section className={`section-${type} ${className}`} list={thelist}>
            {title !== '' && (
                <ListHeader title={title} list={thelist} shown={showamount}>
                    {options.length > 0 && (
                        <SelectControl
                            id={`select-${type}-${title.replace(/\s/, '-')}`}
                            value={sort}
                            options={options}
                            onChange={(e) => {
                                let v = e.target.value;
                                setSort(v);
                                setThelist(sortReducer(list, v));
                                listref.current.scrollTo(0, 0);
                            }}
                        />
                    )}
                    {filters.length > 1 && (
                        <SelectControl
                            id={`filter-${type}-${title.replace(/\s/, '-')}`}
                            value={filter}
                            options={[['', 'Unfiltered'], ...filters]}
                            onChange={(e) => {
                                let v = e.target.value;
                                setFilter(v);
                                const filt = filterReducer(list, ...v.split('\\t'));
                                setThelist(filt);
                                listref.current.scrollTo(0, 0);
                            }}
                        />
                    )}
                </ListHeader>
            )}
            <ListMap
                list={thelist}
                type={type}
                to={to}
                Figure={Figure}
                ref={listref}
                selectId={selectId}
            />
        </Section>
    );
};

export const EmptyList = ({ title = '', type, className = '' }) => {
    return (
        <Section className={`section-${type} ${className}`} list={[0]}>
            {title !== '' && <SectionHeader title={title} shown={false} list={[0]} />}
            <div>It doesn't look like there's any {type} yet.</div>
        </Section>
    );
};

export const ListItem = ({ className = '', children }) => {
    return <li className={`list-item ${className}`}>{children}</li>;
};
export const ListMap = React.forwardRef(
    ({ list = [], type = '', to = () => {}, Figure, selectId }, ref) => {
        return (
            <ul className={`list list-${type}`} ref={ref}>
                {list.map((o, i) => (
                    <ListItem key={`${i}-${o.id}`} className={selectId === o.id ? 'selected' : ''}>
                        <Link to={to(o)}>
                            <Figure data={o} />
                        </Link>
                    </ListItem>
                ))}
            </ul>
        );
    }
);

export const CrumbList = ({ list }) => {
    return (
        <ul className="collapsed crumbs display-flex">
            {list.map((o, i) => (
                <li key={i}>{o[0] !== '' ? <Link to={o[0]}>{o[1]}</Link> : <span>{o[1]}</span>}</li>
            ))}
        </ul>
    );
};

export const ListHeader = ({ list = [], title = '', shown = false, children }) => {
    return (
        <div className="container display-flex flex-align-center">
            <div className="flex-stretch">
                <h2>
                    {title} {shown && `(${list.length})`}
                </h2>
            </div>
            <div className="flex-none display-flex" style={{ gap: '0.5em' }}>
                {children}
            </div>
        </div>
    );
};

export const PersonList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    defaultSort = '',
    jobs = [],
}) => {
    let [itemList, setList] = useState(list);
    let [filterList, setFilterList] = useState([]);

    useEffect(() => {
        setList([...list.filter((o) => o.profile_path), ...list.filter((o) => !o.profile_path)]);

        if (list[0]?.jobs) {
            let jobs = list.reduce((r, o) => [...r, ...o.jobs], []);
            let j = [...new Set(jobs)].sort().map((o) => ['job\\t' + o, o]);
            setFilterList(j);
        }
    }, [list]);

    return (
        <FigureList
            title={title}
            list={itemList}
            showamount={showamount}
            type="person"
            options={sortings[sortorder]}
            filters={filterList}
            defaultSort={defaultSort}
            Figure={f.PersonFigure}
        />
    );
};

export const ComparePersonList = ({ list = [], title = '', emptywarning = false }) => {
    return (
        <>
            {list.length > 0 ? (
                <FigureList
                    list={list}
                    title={title}
                    type="person"
                    Figure={f.ComparePersonFigure}
                    className="people-compare"
                />
            ) : (
                emptywarning && <EmptyList title={title} type="people" />
            )}
        </>
    );
};

export const CompareMovieList = ({ list = [], title = '', emptywarning = false }) => {
    return (
        <>
            {list.length > 0 ? (
                <FigureList
                    list={list}
                    title={title}
                    type="movie"
                    Figure={f.CompareMovieFigure}
                    className="movie-compare"
                />
            ) : (
                emptywarning && <EmptyList title={title} type="movies" />
            )}
        </>
    );
};

export const CompareTVList = ({ list = [], title = '', emptywarning = false }) => {
    return (
        <>
            {list.length > 0 ? (
                <FigureList
                    list={list}
                    title={title}
                    type="tv"
                    Figure={f.CompareTVFigure}
                    className="tv-compare"
                />
            ) : (
                emptywarning && <EmptyList title={title} type="shows" />
            )}
        </>
    );
};

export const ComparePersonResultsList = ({ list = [] }) => {
    return (
        <>
            {list.map((o, i) => (
                <div key={i} className="section section-compare display-flex overflow-x-auto">
                    <ComparePersonList list={o.people} />
                    <CompareMovieList list={o.movie} />
                    <CompareTVList list={o.tv} />
                </div>
            ))}
        </>
    );
};

export const CompareMovieResultsList = ({ list = [] }) => {
    return (
        <>
            {list.map((o, i) => (
                <div key={i} className="section section-compare display-flex overflow-x-auto">
                    <CompareMovieList list={o.movie} />
                    <ComparePersonList list={o.people} />
                </div>
            ))}
        </>
    );
};

export const CompareTVResultsList = ({ list = [] }) => {
    return (
        <>
            {list.map((o, i) => (
                <div key={i} className="section section-compare display-flex overflow-x-auto">
                    <CompareTVList list={o.tv} />
                    <ComparePersonList list={o.people} />
                </div>
            ))}
        </>
    );
};

export const MovieList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    className = '',
    selectId = false,
    jobs = [],
}) => {
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        let sorted = [];
        if (list[0]?.jobs) {
            const jobs = list.reduce((r, o) => [...r, ...o.jobs], []);
            sorted = [...new Set(jobs)].sort().map((o) => ['job\\t' + o, o]);
            // console.log(jobs,sorted)
        }
        if (list[0]?.character) {
            const characters = list.reduce((r, o) => (o.character ? [...r, o.character] : r), []);
            sorted = [...new Set(characters)].sort().map((o) => ['character\\t' + o, o]);
            // console.log(characters,sorted)
        }
        setFilterList(sorted);
    }, [list]);

    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            type="movie"
            Figure={f.MovieFigure}
            options={sortings[sortorder]}
            filters={filterList}
            className={className}
            selectId={selectId}
        />
    );
};

export const TVList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    className = '',
    selectId = false,
}) => {
    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            type="tv"
            Figure={f.TVFigure}
            className={className}
            selectId={selectId}
        />
    );
};

export const TVCreditsList = ({ title = '', sortorder = '', list = [], showamount = false }) => {
    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            options={sortings[sortorder]}
            type="tv"
            to={(o) => `/credit/${o.credit_id}`}
            className="tv-credits-list"
            Figure={f.TVCreditsFigure}
        />
    );
};

export const SeasonList = ({ tv, title = '', list = [], showamount = false }) => {
    return (
        <FigureList
            title={title}
            list={list}
            showamount={showamount}
            type="season"
            to={(o) => `/tv/${tv}/${o.season_number}`}
            Figure={f.SeasonFigure}
        />
    );
};

export const EpisodeList = ({ tv, title = '', list = [], showamount = false }) => {
    return (
        <FigureList
            title={title}
            list={list}
            showamount={showamount}
            type="episode"
            to={(o) => `/tv/${tv}/${o.season_number}/${o.episode_number}`}
            Figure={f.EpisodeFigure}
            badge="episode_count"
        />
    );
};
