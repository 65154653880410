import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { query, getAge, siftCrew, getYear, uniques } from '../parts/functions';
import { FigureGallery, FigureImage, FigureModal } from '../parts/figure';
import { PersonOverlay } from '../parts/overlay';
import { MovieList, TVCreditsList } from '../parts/list';
import { Container, OpengraphHelmet, Page, Section, SingleProperty } from '../parts/page';
import { useStoreContext } from '../store/storecontext';

export const Companies = ({ companies }) => {
    return (
        <>
            {companies.map((o, i) => (
                <div className="company" key={i}>
                    <FigureImage src={o.logo_path} />
                </div>
            ))}
        </>
    );
};

const makeYear = (o) => {
    o.release_year = getYear(o.release_date || o.first_air_date);
};

export const PersonProfile = ({ person, setModal }) => {
    //    const [{settings:{adult}}] = useStoreContext();
    const openPhotoModal = () => {
        setModal(person.profile_path);
    };
    return person ? (
        <div className="person-info">
            <div className="single-image">
                <FigureImage
                    className="canclick"
                    src={person.profile_path}
                    onClick={openPhotoModal}
                />
                <PersonOverlay data={person} />
            </div>
            <div className="single-description">
                <div className="single-name">
                    <a href={`/person/${person.id}`}>{person.name}</a>
                </div>

                {person.imdb_id !== null && (
                    <div className="single-imdb">
                        <a
                            href={`https://imdb.com/name/${person.imdb_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            IMDB
                        </a>
                    </div>
                )}

                <SingleProperty title="Age" value={getAge(person)} />
                <SingleProperty title="Birth Date" value={person.birthday} />
                <SingleProperty title="Death" value={person.deathday} />
                <SingleProperty title="Place of Birth" value={person.place_of_birth} />
                <SingleProperty title="Biography" value={person.biography} more paragraph />
            </div>
        </div>
    ) : (
        <></>
    );
};

export const PersonPage = () => {
    const { personParam } = useParams();

    const [person, setPerson] = useState(false);
    const [modal, setModal] = useState(false);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setPerson(false);
        query(
            'person/' + personParam,
            '&append_to_response=images,movie_credits,tv_credits&include_image_language=null,en',
            adult
        ).then((d) => {
            d.movie_credits.cast.forEach(makeYear);
            d.movie_credits.crew.forEach(makeYear);
            d.tv_credits.cast.forEach(makeYear);
            d.tv_credits.crew.forEach(makeYear);
            // console.log(d);
            setPerson(d);
        });
    }, [adult, personParam]);

    const opengraph = person
        ? {
              title: person.name,
              description: person.biography,
              image: person.profile_path,
              url: '/person/' + person.id,
          }
        : null;

    return (
        <Page>
            {person && (
                <>
                    <OpengraphHelmet opengraph={opengraph} />
                    <Container>
                        <PersonProfile person={person} setModal={setModal} />
                    </Container>

                    <FigureGallery
                        className="profiles"
                        images={person.images.profiles}
                        setModal={setModal}
                        adultHandler={(o) => !adult && o.adult}
                    />

                    <Section
                        className="section-movie"
                        list={[...person.movie_credits.cast, ...person.movie_credits.crew]}
                    >
                        <MovieList
                            title="Movie Cast"
                            list={person.movie_credits.cast}
                            showamount={true}
                            sortorder="personcast"
                            jobs={uniques(
                                person.movie_credits.cast.reduce((r, o) => [...r, o.character], [])
                            )}
                        />
                        <MovieList
                            title="Movie Crew"
                            list={siftCrew(person.movie_credits.crew)}
                            showamount={true}
                            sortorder="personcrew"
                            jobs={uniques(
                                person.movie_credits.crew.reduce((r, o) => [...r, o.job], [])
                            )}
                        />
                    </Section>

                    <Section
                        className="section-tv"
                        list={[...person.tv_credits.cast, ...person.tv_credits.crew]}
                    >
                        <TVCreditsList
                            title="TV Cast"
                            list={person.tv_credits.cast}
                            showamount={true}
                            sortorder="tvcast"
                        />
                        <TVCreditsList
                            title="TV Crew"
                            list={person.tv_credits.crew}
                            showamount={true}
                        />
                    </Section>

                    {modal && <FigureModal src={modal} setSrc={setModal} />}
                </>
            )}
        </Page>
    );
};
