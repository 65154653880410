import React, { forwardRef } from 'react';

const f = () => {};

export const Form = ({
        id,
        className='',
        onSubmit=f,
        children
    }) => {

    const handlesubmit = e => {
        onSubmit(e);
        e.preventDefault();
    }

    return <form
        id={id}
        className={`form ${className}`}
        onSubmit={handlesubmit}>{children}</form>;
}

export const Input = forwardRef(({
        id,
        className='',
        type='text',
        placeholder='Input',
        ...props
    }, ref) => {

    return <input
        id={id}
        className={`form-input ${className}`}
        type={type}
        placeholder={placeholder}
        ref={ref}
        {...props} />
});

export const Button = ({
        type='button',
        className='',
        onClick=f,
        children
    }) => {

    const handleclick = e => onClick(e);

    return <input
        type={type}
        className={`form-button ${className}`}
        onClick={handleclick} value={children} />
}

export const Select = ({
        className='',
        id,
        value="",
        onChange=f,
        options
    }) => {

    const handlechange = e => onChange(e);

    return <label className={`form-select ${className}`} htmlFor={id}>
        <select id={id} value={value} onChange={handlechange}>
            {options.map(([v,t],k)=><option key={k} value={v}>{t}</option>)}
        </select>
    </label>;
}

export const Label = ({id='',className='',children}) => {
    return <label htmlFor={id} className={`form-label ${className}`}>{children}</label>;
}

export const Control = ({className='',children}) => {
    return <div className={`form-control ${className}`}>{children}</div>;
}

export const TextControl = ({
        id,
        value='',
        placeholder='Insert Text',
        type='text',
        onChange=f,
        children
    }) => {
    return <Control>
        <Label id={id}>{children}</Label>
        <Input id={id} type={type} onChange={onChange} value={value} placeholder={placeholder} />
    </Control>;
}

export const SelectControl = ({
        id,
        value='',
        onChange=f,
        options,
        children
    }) => {
    return <Control>
        {children && <Label id={id}>{children}</Label>}
        <Select id={id} value={value} onChange={onChange} options={options} />
    </Control>;
}