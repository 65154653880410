import React, { useState, useEffect } from 'react';

import { PersonOverlay, MovieOverlay, TVOverlay } from './overlay';
import { getYear } from './functions';
import { LazyImageLoader } from './lazyimage';
import { useStoreContext } from '../store/storecontext';

export const imageurl = (src, quality = 200) => {
    const q = quality > 0 ? 'w' + quality : 'original';
    return `https://image.tmdb.org/t/p/${q}${src}`;
};

export const LazyFigureImage = ({ quality = 200, src = '', alt = '', adult }) => {
    return (
        <LazyImageLoader offset={1000}>
            {src && (
                <img
                    className={`media-image ${adult ? 'adult-image' : ''}`}
                    alt={alt}
                    src={imageurl(src, quality)}
                />
            )}
        </LazyImageLoader>
    );
};

export const FigureImage = ({
    className = '',
    quality = 200,
    src = '',
    alt = '',
    adult,
    onClick = () => {},
}) => {
    return (
        <>
            {src && (
                <img
                    className={`media-image ${adult ? 'adult-image' : ''} ${className}`}
                    alt={alt}
                    src={imageurl(src, quality)}
                    onClick={onClick}
                />
            )}
        </>
    );
};

export const Figure = ({ className = '', children }) => {
    return <div className={`figure ${className}`}>{children}</div>;
};

export const FigureModal = ({ src, setSrc }) => {
    return (
        <div className="modal active">
            <div
                onClick={() => {
                    setSrc(false);
                }}
                className="modal-fullimage canclick"
            >
                <img className="noclick" src={imageurl(src, -1)} alt="" />
            </div>
        </div>
    );
};

const ImageFigure = ({
    image = '',
    Overlay = null,
    lazyload = true,
    badge = '',
    adult,
    children,
}) => {
    return (
        <Figure>
            <div className="item-image">
                {lazyload ? (
                    <LazyFigureImage src={image} adult={adult} />
                ) : (
                    <FigureImage src={image} adult={adult} />
                )}
                {Overlay}
                {badge !== '' && <div className="badge">{badge}</div>}
            </div>
            <div className="item-description">{children}</div>
        </Figure>
    );
};

export const ComparePersonFigure = ({ data }) => {
    return (
        <ImageFigure
            image={data.profile_path || data.img}
            lazyload={false}
            Overlay={<PersonOverlay data={data} position="top right" />}
        >
            <div className="item-title" title={data.name || data.title}>
                <strong className="marquee">{data.name || data.title}</strong>
            </div>
        </ImageFigure>
    );
};
export const CompareMovieFigure = ({ data }) => {
    return (
        <ImageFigure
            image={data.poster_path || data.img}
            lazyload={false}
            Overlay={<MovieOverlay data={data} position="top right" />}
        >
            <div className="item-title" title={data.title}>
                <strong className="marquee">{data.title}</strong>
            </div>
        </ImageFigure>
    );
};
export const CompareTVFigure = ({ data }) => {
    return (
        <ImageFigure
            image={data.poster_path || data.img}
            lazyload={false}
            Overlay={<TVOverlay data={data} />}
        >
            <div className="item-title" title={data.name || data.title}>
                <strong className="marquee">{data.name || data.title}</strong>
            </div>
        </ImageFigure>
    );
};

export const PersonFigure = ({ data }) => {
    let [roles, setRoles] = useState([]);
    let [jobs, setJobs] = useState([]);
    useEffect(() => {
        if (data.roles) {
            setRoles(data.roles.map((o) => o.character));
        }
        if (data.jobs) {
            setJobs(data.jobs);
        }
    }, [data]);
    return (
        <ImageFigure image={data.profile_path || data.img} Overlay={<PersonOverlay data={data} />}>
            <div className="item-name" title={data.name}>
                <strong className="marquee">{data.name}</strong>
            </div>
            {data.character && (
                <div className="item-jobs" title={data.character}>
                    {data.character}
                </div>
            )}
            {roles.length > 0 && (
                <div className="item-jobs" title={roles.join(', ')}>
                    {roles.map((o, i) => (
                        <div key={i + '_' + o?.id} className="item-job">
                            {o}
                        </div>
                    ))}
                </div>
            )}
            {jobs.length > 0 && (
                <div className="item-jobs" title={jobs.join(', ')}>
                    {jobs.map((o, i) => (
                        <div key={i + '_' + o?.id} className="item-job">
                            {o}
                        </div>
                    ))}
                </div>
            )}
        </ImageFigure>
    );
};

export const MovieFigure = ({ data }) => {
    // console.log(data)
    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    return (
        <ImageFigure
            image={data.poster_path || data.img}
            adult={!adult && data.adult}
            Overlay={<MovieOverlay data={data} />}
        >
            <div className="item-title" title={data.title}>
                <span className="marquee">{data.title}</span>
            </div>
            {data.release_year && <div className="item-year">{data.release_year}</div>}
            {data.release_date && !data.release_year && (
                <div className="item-year">{data.release_date}</div>
            )}
            {data.character && (
                <div className="item-jobs" title={data.character}>
                    {data.character}
                </div>
            )}
            {data.jobs && (
                <div className="item-jobs" title={data.jobs.join(', ')}>
                    {(data.jobs || []).map((o, i) => (
                        <div key={i} className="item-job">
                            {o}
                        </div>
                    ))}
                </div>
            )}
        </ImageFigure>
    );
};

export const TVFigure = ({ data }) => {
    return (
        <ImageFigure image={data.poster_path || data.img} Overlay={<TVOverlay data={data} />}>
            <div className="item-title" title={data.name}>
                {data.name}
            </div>
            <div className="item-year">{data.first_air_date}</div>
            {data.character && data.character !== '' && (
                <div className="item-jobs">{data.character}</div>
            )}
            {data.job && data.job !== '' && <div className="item-jobs">{data.job}</div>}
        </ImageFigure>
    );
};

export const TVCreditsFigure = ({ data }) => {
    return (
        <ImageFigure
            image={data.poster_path || data.img}
            Overlay={<TVOverlay data={data} />}
            badge={data.episode_count}
        >
            <div className="item-title" title={data.name}>
                {data.name}
            </div>
            <div className="item-year">{data.first_air_date}</div>
            {data.character && data.character !== '' && (
                <div className="item-jobs">{data.character}</div>
            )}
            {data.job && data.job !== '' && <div className="item-jobs">{data.job}</div>}
        </ImageFigure>
    );
};

export const SeasonFigure = ({ data }) => {
    return (
        <ImageFigure image={data.poster_path} badge={data.episode_count}>
            <div className="item-title">{data.name}</div>
            <div className="item-year">{getYear(data.air_date, '')}</div>
        </ImageFigure>
    );
};

export const EpisodeFigure = ({ data }) => {
    return (
        <ImageFigure image={data.still_path} badge={data.runtime && `${data.runtime}m`}>
            <div className="item-title" title={data.name}>
                {data.episode_number}. {data.name}
            </div>
            <div className="item-year">{data.air_date}</div>
        </ImageFigure>
    );
};

export const FigureGallery = ({ images, setModal, adultHandler, className = '' }) => {
    return (
        <ul className={`${className} container figure-gallery`}>
            {images.length > 1 &&
                images.map((o, i) => (
                    <li key={i}>
                        <FigureImage
                            src={o.file_path}
                            adult={adultHandler(o)}
                            alt=""
                            onClick={() => {
                                setModal(o.file_path);
                            }}
                        />
                    </li>
                ))}
        </ul>
    );
};
