import React, { useEffect } from 'react';

import { MovieList } from '../parts/list';
import { Page } from '../parts/page';
import { query } from '../parts/functions';
import { useInfiniteLoading } from '../parts/infiniteLoading';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';

const filterTypes = {
    genre: 'with_genres',
    year: 'primary_release_year',
    keyword: 'with_keywords',
    company: 'with_companies',
};

const sortTypes = [
    'popularity.desc',
    'popularity.asc',
    'revenue.desc',
    'revenue.asc',
    'primary_release_date.desc',
    'primary_release_date.asc',
    'vote_average.desc',
    'vote_average.asc',
    'vote_count.desc',
    'vote_count.asc',
];

const FilterType = () => {
    let [searchParams] = useSearchParams();
    let { filterType, filterValue, filterName } = useParams();
    const [results, setResults] = React.useState({});

    const getItems = ({ page }) => {
        return query(
            `discover/movie`,
            `&${filterTypes[filterType]}=${filterValue}&page=${page}&sort_by=${
                sortTypes[searchParams.get('sort') || 0]
            }`
        ).then((d) => {
            setResults(d);
            return { items: d.results, totalPages: d.total_pages };
        });
    };

    const { items, hasNext, hasPrevious, loadNext, loadPrevious, loadMoreRef } = useInfiniteLoading(
        {
            getItems,
            options: {
                loadingType: 'partial',
                preload: 'off',
                partialInfiniteLimit: 6,
            },
            restartTrigger: searchParams.get('sort'),
        }
    );

    useEffect(() => {
        if (searchParams.get('sort') !== undefined) {
        }
    }, [searchParams]);

    //    const handleSort = (e) => {
    //       setSearchParams((new URLSearchParams({sort:e.target.value}).toString()));
    //    }

    return (
        <Page>
            {/* <select value={searchParams.get('sort')} onChange={handleSort}>
         {sortTypes.map((o,i)=><option key={i} value={i}>{o}</option>)}
      </select> */}
            {hasPrevious && (
                <button className="btn--load-more" type="button" onClick={() => loadPrevious()}>
                    Load Previous
                </button>
            )}
            {items.length > 0 ? (
                <>
                    <div className="container" style={{ textTransform: 'capitalize' }}>
                        <h2>
                            {filterType} : {filterName ? filterName : filterValue}
                        </h2>
                        <div>{results.total_results} results</div>
                    </div>
                    <MovieList className="fullsize" title="" list={items} />
                </>
            ) : (
                <div>Loading...</div>
            )}
            {hasNext && (
                <div style={{ textAlign: 'center' }}>
                    <button
                        ref={loadMoreRef}
                        className="btn btn--load-more"
                        type="button"
                        onClick={() => loadNext()}
                    >
                        Load Next
                    </button>
                </div>
            )}
        </Page>
    );
};

export const MoviesPage = () => {
    return (
        <Routes>
            <Route path=":filterType" element={<FilterType />}>
                <Route path=":filterValue" element={<FilterType />}>
                    <Route path=":filterName" element={<FilterType />} />
                </Route>
            </Route>
        </Routes>
    );
};
