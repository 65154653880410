import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { query, order, fixTVCrew, uniques, getYear } from '../parts/functions';
import { FigureImage, FigureModal } from '../parts/figure';
import { TVList, PersonList, SeasonList } from '../parts/list';
import {
    ChildProperty,
    Container,
    OpengraphHelmet,
    Page,
    Section,
    SingleProperty,
} from '../parts/page';
import { TVOverlay } from '../parts/overlay';
import { AmazonLink, IMDBLink } from '../parts/links';

export const Companies = ({ companies }) => {
    return (
        <>
            {companies.map((o, i) => (
                <div className="company" key={i}>
                    <FigureImage src={o.logo_path} />
                </div>
            ))}
        </>
    );
};

const Genres = ({ genres }) => {
    return genres ? (
        <div className="comma-list genres">
            {genres.map((o, i) => (
                <Link key={i} to={`/shows/genre/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </div>
    ) : (
        <></>
    );
};

// const Keywords = ({keywords}) => {
//    return <div className="comma-list keywords">
//       {keywords.map((o,i)=><Link key={i} to={`/shows/keyword/${o.id}/${o.name}`}>{o.name}</Link>)}
//    </div>;
// }

export const TVProfile = ({ tv, setModal }) => {
    return tv ? (
        <div className="tv-info">
            <div className="single-image canclick">
                <FigureImage
                    src={tv.poster_path}
                    onClick={() => {
                        setModal(tv.poster_path);
                    }}
                />
                <TVOverlay data={tv} />
            </div>
            <div className="single-description">
                <div className="single-name">
                    <a href={`/tv/${tv.id}`}>{tv.name}</a>
                </div>

                <div className="single-property">
                    <IMDBLink id={tv.imdb_id} label="IMDb" />
                    <AmazonLink
                        search={`${tv.name} ${tv.first_air_date.slice(0, 4)}`}
                        label="Amazon"
                    />
                </div>

                <SingleProperty title="First Air Date" value={tv.first_air_date} />
                <SingleProperty title="Last Air Date" value={tv.last_air_date} />
                <ChildProperty title="Genres" length={tv.genres?.length}>
                    <Genres genres={tv.genres} />
                </ChildProperty>
                <SingleProperty title="Overview" value={tv.overview} />
            </div>
        </div>
    ) : (
        <></>
    );
};

export const TVPage = () => {
    let { tvParam } = useParams();
    let [tv, setTv] = useState(false);
    let [modal, setModal] = useState(false);

    useEffect(() => {
        setTv(false);
        query(
            'tv/' + tvParam,
            '&append_to_response=credits,videos,images,recommendations,similar,external_ids&include_image_language=null,en'
        ).then((d) => {
            fixTVCrew(d, d);
            d.imdb_id = d.imdb_id ?? d.external_ids?.imdb_id ?? false;
            setTv(d);
        });
    }, [tvParam]);

    const opengraph = tv
        ? {
              title: `${tv.name} (${getYear(tv.first_air_date)} - ${getYear(tv.last_air_date)})`,
              description: tv.overview,
              image: tv.poster_path,
              url: '/tv/' + tv.id,
          }
        : null;

    return (
        <Page>
            {tv && (
                <>
                    <OpengraphHelmet opengraph={opengraph} />
                    <Container>
                        <TVProfile tv={tv} setModal={setModal} />
                    </Container>

                    <SeasonList list={tv.seasons} tv={tv.id} title="season" />

                    <Section className="section-credits" list={[0]}>
                        <PersonList
                            title="Cast"
                            list={order(tv.credits.cast)}
                            showamount={true}
                            sortorder="tvcast"
                        />
                        <PersonList
                            title="Crew"
                            list={order(tv.credits.crew)}
                            showamount={true}
                            sortorder="tvcrew"
                            jobs={uniques(tv.credits.crew.reduce((r, o) => [...r, o.job], []))}
                        />
                    </Section>

                    <Section className="section-recommendations" list={[0]}>
                        <TVList title="Recommendations" list={tv.recommendations.results} />
                        <TVList title="Similar" list={tv.similar.results} />
                    </Section>
                </>
            )}
            {modal && <FigureModal src={modal} setSrc={setModal} />}
        </Page>
    );
};
