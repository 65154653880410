import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { queries, order, fixTVCrew, getYear } from '../parts/functions';
import { FigureImage, FigureModal } from '../parts/figure';
import { PersonList, EpisodeList, CrumbList } from '../parts/list';
import { OpengraphHelmet, Page, Section, SingleProperty } from '../parts/page';
import { AmazonLink, IMDBLink } from '../parts/links';
import { VideoThumbs } from '../parts/video';
import { Modal } from '../parts/modal';

export const SeasonPage = () => {
    let { tvParam, seasonParam } = useParams();
    let [series, setSeries] = useState(false);
    let [season, setSeason] = useState(false);
    let [modal, setModal] = useState(false);
    let [imdb, setImdb] = useState(false);

    let [videoModal, setVideoModal] = useState(false);

    useEffect(() => {
        setSeason(false);
        queries([
            [
                'tv/' + tvParam,
                '&append_to_response=credits,videos,images,external_ids&include_image_language=null,en',
            ],
            [
                `tv/${tvParam}/season/${seasonParam}`,
                '&append_to_response=aggregate_credits,videos,images&include_image_language=null,en',
            ],
        ]).then((d) => {
            d[1] = {
                ...d[1],
                tv_id: tvParam,
                tv_name: d[0].name,
                tv_imdb_id: d[0].imdb_id,
                credits: d[1].aggregate_credits,
            };

            // console.log(d);

            fixTVCrew(d[0], d[1]);

            setSeries(d[0]);
            setSeason(d[1]);
            setImdb(d[0].imdb_id ?? d[0].external_ids?.imdb_id ?? false);
        });
    }, [seasonParam, tvParam]);

    const opengraph = season
        ? {
              title: `${series.name} - ${season.name}`,
              description: season.overview,
              image: season.poster_path,
              url: `/tv/${tvParam}/${seasonParam}}`,
          }
        : null;

    return (
        <Page>
            {season && (
                <>
                    <OpengraphHelmet opengraph={opengraph} />
                    <div className="tv-info container">
                        <div className="single-image canclick">
                            <FigureImage
                                src={season.poster_path}
                                onClick={() => {
                                    setModal(season.poster_path);
                                }}
                            />
                        </div>
                        <div className="single-description">
                            <CrumbList
                                list={[
                                    ['/tv/' + season.tv_id, season.tv_name],
                                    ['', season.name],
                                ]}
                            />

                            <div className="single-property">
                                <IMDBLink id={imdb} label="IMDb" />
                                <AmazonLink search={season.tv_name} label="Amazon" />
                            </div>

                            <SingleProperty title="Year" value={getYear(season.air_date)} />
                            <SingleProperty
                                title="Overview"
                                value={season.overview === '' ? series.overview : season.overview}
                            />
                        </div>
                    </div>

                    <EpisodeList
                        list={season.episodes}
                        tv={tvParam}
                        title={`${season.episodes.length} Episodes`}
                    />

                    {season?.videos?.results?.length > 0 && (
                        <SingleProperty
                            title="Videos"
                            value={
                                <VideoThumbs
                                    movies={season.videos.results}
                                    showModal={setVideoModal}
                                />
                            }
                        />
                    )}

                    <Section className="section-credits" list={[0]}>
                        <PersonList
                            title="Cast"
                            list={order(season.credits.cast)}
                            showamount={true}
                        />
                        <PersonList
                            title="Crew"
                            list={order(season.credits.crew)}
                            showamount={true}
                        />
                    </Section>
                </>
            )}
            {modal && <FigureModal src={modal} setSrc={setModal} />}
            <Modal
                show={videoModal !== false}
                close={() => setVideoModal(false)}
                content={
                    videoModal ? (
                        <iframe
                            src={`https://www.youtube.com/embed/${videoModal.key}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    ) : (
                        <></>
                    )
                }
            />
        </Page>
    );
};
