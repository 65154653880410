import React from 'react';

import { Page, Container } from '../parts/page';
import { useStoreContext } from '../store/storecontext';

export const SettingsPage = () => {
   const [{settings},setStore] = useStoreContext();

   const handleBoolean = (k,v) => {
      setStore({ settings: { ...settings, [k]:v, } });
   }

   return <Page>
      <Container>
         <h2>Settings</h2>

         <div className="setting">
            <label htmlFor="settings-adult">Adult Search Results?</label>
            <input type="checkbox" checked={settings.adult} onChange={()=>handleBoolean('adult',!settings.adult)} />
         </div>

         <footer className="container" style={{padding:"3em 0"}}>
            This application uses data pulled from <a href="http://themoviedb.org" target="_blank" rel="noopener noreferrer">The Movie DB</a><br/>
            Get started making your own <a href="https://developers.themoviedb.org/3/getting-started/introduction" rel="noopener noreferrer">movie app</a>
         </footer>
      </Container>
   </Page>;
}