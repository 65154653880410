import React, { useEffect, useState } from 'react';

import { useStoreContext } from '../store/storecontext';



const CompareOverlay = ({position="bottom left",type,id,img,title}) => {
   const [{compare},setStore] = useStoreContext();
   let [isComparing,setComparing] = useState(false);

   const setToCompare = async (e) => {
      e.preventDefault();
      if (compare.some(o=>o.id===id)) {
         setStore({compare:compare.filter(o=>o.id!==id)})
      } else {
         setStore({compare:[...compare, {type,id,img,title}]})
      }
   }

   useEffect(()=>{
      setComparing(compare.some(o=>o.id===id));
   }, [compare,id]);

   return <div className={`overlay figure-overlay ${isComparing&&"compare-selected"}`}>
      <div className="overlay-back"></div>
      <div className={`floater ${position}`}>
         <button className="compare-select-button" onClick={setToCompare}></button>
      </div>
   </div>;
}


export const PersonOverlay = ({data,position="bottom left"}) => {
   return <CompareOverlay
      position={position}
      type="person"
      id={data.id}
      img={data.profile_path}
      title={data.name}
      />;
}

export const MovieOverlay = ({data,position="bottom left"}) => {
   return <CompareOverlay
      position={position}
      type="movie"
      id={data.id}
      img={data.poster_path}
      title={data.title}
      />;
}

export const TVOverlay = ({data,position="bottom left"}) => {
   return <CompareOverlay
      position={position}
      type="tv"
      id={data.id}
      img={data.poster_path}
      title={data.name}
      />;
}
