import React from 'react';

import { Link, Route, Routes, useMatch, useResolvedPath } from 'react-router-dom';
import { Page, Container, HeaderList } from '../parts/page';
import { ComparePersonList, CompareMovieList, CompareTVList } from '../parts/list';

import { useStoreContext } from '../store/storecontext';
import { CompareMovies } from './CompareMovies';
import { CompareShows } from './CompareShows';
import { ComparePeople } from './ComparePeople';

const findPeople = (compare) => compare.filter((o) => o.type === 'person');
const findMovies = (compare) => compare.filter((o) => o.type === 'movie');
const findTV = (compare) => compare.filter((o) => o.type === 'tv');

const MainComparePage = ({ compareTypes }) => {
    const person = findPeople(compareTypes);
    const movie = findMovies(compareTypes);
    const tv = findTV(compareTypes);
    return (
        <>
            <Container>
                <p>
                    Have you selected some things below? Then choose a section above and compare
                    them. This main page is more or less clerical right now.
                </p>
                {person.length === 0 && (movie.length === 0) & (tv.length === 0) ? (
                    <p>Time to go find some people to compare. This page looks empty.</p>
                ) : (
                    <>
                        <ComparePersonList list={person} emptywarning={true} />
                        <CompareMovieList list={movie} emptywarning={true} />
                        <CompareTVList list={tv} emptywarning={true} />
                    </>
                )}
            </Container>
        </>
    );
};

function HeaderLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link className={match ? 'active' : ''} to={to} {...props}>
            {children}
        </Link>
    );
}

export const ComparePage = () => {
    const [{ compare }] = useStoreContext();

    return (
        <Page className="compare-page">
            <HeaderList>
                <HeaderLink to=".">Main</HeaderLink>
                <HeaderLink to="person">People</HeaderLink>
                <HeaderLink to="movie">Movies</HeaderLink>
                <HeaderLink to="tv">Shows</HeaderLink>
            </HeaderList>
            <Routes>
                <Route path="/" element={<MainComparePage compareTypes={compare} />} />
                <Route path="person/" element={<ComparePeople compare={findPeople(compare)} />} />
                <Route
                    path="person/:compareOption"
                    element={<ComparePeople compare={findPeople(compare)} />}
                />
                <Route path="movie/" element={<CompareMovies compare={findMovies(compare)} />} />
                <Route
                    path="movie/:compareOption"
                    element={<CompareMovies compare={findMovies(compare)} />}
                />
                <Route path="tv/" element={<CompareShows compare={findTV(compare)} />} />
                <Route
                    path="tv/:compareOption"
                    element={<CompareShows compare={findTV(compare)} />}
                />
            </Routes>
        </Page>
    );
};
